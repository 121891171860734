@import './../variables';

.mat-simple-snackbar {
  font-size: 16px;
}

.wp-snackbar-success {
  --mdc-snackbar-container-color: #47a540;
  --mdc-snackbar-supporting-text-color: #fff;
}
.wp-snackbar-error {
  --mdc-snackbar-container-color: #d52504;
  --mdc-snackbar-supporting-text-color: #fff;
}
.wp-snackbar-warn {
  --mdc-snackbar-container-color: #ff9600;
  --mdc-snackbar-supporting-text-color: #fff;
}
