@import './../variables';

.mdc-checkbox {
  --mdc-checkbox-selected-checkmark-color: white;
  padding: 0px !important;
}

.mdc-checkbox__background {
  border-width: 1px !important;
  top: 0 !important;
  left: 0 !important;
  width: 18px !important;
  height: 18px !important;
}

.mat-checkbox-label {
  font-family: 'Open Sans', sans-serif;
}

.mat-checkbox-disabled .mat-checkbox-label {
  color: $haze-dark;
}

.mdc-checkbox__native-control {
  width: 18px !important;
  height: 18px !important;
}

.mdc-checkbox__ripple {
  top: -9px !important;
  left: -9px !important;
  right: -9px !important;
  bottom: -9px !important;
}

.mat-checkbox.wp-checkbox.large {
  .mat-checkbox-inner-container {
    //width: 20px;
    //height: 20px;

    .mat-checkbox-ripple {
      left: calc(50% - 23px);
      top: calc(50% - 23px);
      height: 46px;
      width: 46px;
    }
  }
}
