// remove border from google maps tooltip "x" button
//.gm-style-iw button:focus {
//  outline: 0;
//}

// remove "x" button from google maps tooltip
.gm-style-iw button {
  display: none !important;
}
// remove border from google maps tooltip
.gm-style-iw {
  outline: 0;
}
// remove blue focus outline from google maps
.gm-style iframe + div {
  border: none !important;
}

// custom popup window base styles

.map-popup-bubble {
  /* Position the bubble centred-above its parent. */
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -100%);
  overflow-y: auto;
  border-radius: 2px;
}

/* The parent of the bubble. A zero-height div at the top of the tip. */
.map-popup-bubble-anchor {
  /* Position the div a fixed distance above the tip. */
  position: absolute;
  width: 100%;
  bottom: -8px;
  left: 0;
}

///* This element draws the tip. */
//.map-popup-bubble-anchor::after {
//  content: "";
//  position: absolute;
//  top: -95px;
//  left: 0;
//  transform: translate(-50%, 0);
//  /* The tip is a https://css-tricks.com/snippets/css/css-triangle/ */
//  width: 0;
//  height: 0;
//  /* The tip is 8px high, and 12px wide. */
//  border-left: 6px solid transparent;
//  border-right: 6px solid transparent;
//  border-bottom: 8px solid  #636365;
//}

/* JavaScript will position this div at the bottom of the popup tip. */
.map-popup-container {
  cursor: auto;
  height: 0;
  position: absolute;
  /* The max width of the info window. */
  width: 200px;
}

// Parkout polygon tooltip styles

.parkout-tooltip-holder {
  border: 1px solid #636365;
  border-radius: 2px;

  .parkout-tooltip-header {
    padding: 4px 16px;
    background: #636365;
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    line-height: 1.5;
  }

  .parkout-tooltip-link-holder {
    background: #fff;
    padding: 12px 16px;

    .tooltip-parkout-link {
      font-size: 14px;
      line-height: 1.4;
      color: #191919;
      background: none;
      border: none;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
