.mat-form-field {
  font-family: inherit;
}

//// show label for more dense inputs as well
.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: inline !important;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--disabled):hover {
  background-color: #f8f8fc !important;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--disabled) .mat-mdc-form-field-focus-overlay {
  background-color: #efeff1 !important;
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 17px !important;
  padding-bottom: 3px !important;
  min-height: 42px !important;
}

.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper {
  padding: 0 !important;
  font-size: 12px;
}

.mat-mdc-form-field {
  --mdc-filled-text-field-container-color: rgba(0, 0, 0, 0);
  --mat-form-field-focus-state-layer-opacity: 1;
  width: 100%;
}

.mdc-text-field--filled {
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
}

.mat-mdc-form-field .mat-mdc-form-field-flex {
  background-color: rgba(0, 0, 0, 0);
}

.mdc-text-field {
  padding: 0 !important;
}
