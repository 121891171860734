// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming

// @use rules must be written before any other rules.
@use '@angular/material' as mat;

// overlay is needed for items with fixed positioning to
// display properly (like tooltips, dropdowns, etc)
@import '@angular/cdk/overlay-prebuilt.css';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/ , http://mcg.mbitson.com/
$rich-green: (
  50: #e9f6e8,
  100: #cae8c8,
  200: #a8d9a5,
  300: #85cb80,
  400: #6ac065,
  500: #50b449,
  600: #47a540,
  700: #3b9335,
  800: #30822b,
  900: #1a6318,
  A100: #b9f6ca,
  A200: #69f0ae,
  A400: #00e676,
  A700: #00c853,
  contrast: (
    50: #191919,
    100: #191919,
    200: #191919,
    300: #191919,
    400: #191919,
    500: #191919,
    600: #191919,
    700: #191919,
    800: #191919,
    900: #191919,
    A100: #191919,
    A200: #191919,
    A400: #191919,
    A700: #191919,
  ),
);

$red-palette: (
  50: #fae5e1,
  100: #f2beb4,
  200: #ea9282,
  300: #e2664f,
  400: #db462a,
  500: #d52504,
  600: #d02103,
  700: #ca1b03,
  800: #c41602,
  900: #ba0d01,
  A100: #ffe4e3,
  A200: #ffb2b0,
  A400: #ff817d,
  A700: #ff6863,
  contrast: (
    50: #191919,
    100: #191919,
    200: #191919,
    300: #191919,
    400: #191919,
    500: #191919,
    600: #191919,
    700: #191919,
    800: #191919,
    900: #191919,
    A100: #191919,
    A200: #191919,
    A400: #191919,
    A700: #191919,
  ),
);
$web-portal-primary: mat.m2-define-palette($rich-green);
$web-portal-accent: mat.m2-define-palette($red-palette);

// The warn palette is optional (defaults to red).
$web-portal-warn: mat.m2-define-palette($red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$web-portal-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $web-portal-primary,
      accent: $web-portal-accent,
      warn: $web-portal-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.core();
@include mat.core-theme($web-portal-theme);

@include mat.autocomplete-theme($web-portal-theme);
@include mat.checkbox-theme($web-portal-theme);
@include mat.form-field-theme($web-portal-theme);
@include mat.input-theme($web-portal-theme);
@include mat.menu-theme($web-portal-theme);
@include mat.progress-bar-theme($web-portal-theme);
@include mat.progress-spinner-theme($web-portal-theme);
@include mat.radio-theme($web-portal-theme);
@include mat.select-theme($web-portal-theme);
@include mat.slider-theme($web-portal-theme);
@include mat.snack-bar-theme($web-portal-theme);
@include mat.tabs-theme($web-portal-theme);
@include mat.tooltip-theme($web-portal-theme);

@include mat.form-field-density(-3);
@include mat.tooltip-density(-1);

/* You can add global styles to this file, and also import other style files */
@import './app/styles/index';

// Helper classes
@import './app/styles/helpers';

body {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  color: $charcoal;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  line-height: 20px;
}

.flip-list-move {
  transition: transform 1s;
}

.mat-menu-panel {
  max-width: none !important;
  &.black-background {
    background-color: rgba(0, 0, 0, 0.84);
    color: white;
  }
}

/**
  * Custom scroll
  */
.customScroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #f7f7f7;
  background-color: #f7f7f7;
}

.customScroll::-webkit-scrollbar {
  cursor: pointer;
  width: 6px;
  background-color: #0000004d;
}

.customScroll::-webkit-scrollbar-thumb {
  background-color: #d8d8d8;
  border-radius: 2px;
  &:hover {
    background-color: #acacac;
  }
}

.cdk-virtual-scrollable ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #f7f7f7;
  background-color: #f7f7f7;
}

.cdk-virtual-scrollable::-webkit-scrollbar {
  cursor: pointer;
  width: 6px;
  background-color: #0000004d;
}

.cdk-virtual-scrollable::-webkit-scrollbar-thumb {
  background-color: #d8d8d8;
  border-radius: 2px;
  &:hover {
    background-color: #acacac;
  }
}

.mat-checkbox-align-top {
  ::ng-deep .mat-checkbox-inner-container {
    margin-top: 4px;
  }
}

.hoverable-text-green {
  cursor: pointer;
  &:hover {
    color: #47a540;
  }
}

.hoverable-close-svg {
  * {
    cursor: pointer;
    &:hover {
      fill: #47a540;
      stroke: #47a540;
    }
  }
}

/* clears the 'X' from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

// support hover to options added to the map
.portal-option:not(.disabled):hover {
  background-color: #DFDFE3 !important;
}

// selected item
.portal-selected {
  background-color: $selected-background-color !important;
  &.outer-bar {
    box-shadow: calc(-1 * var(--portal-selected-bar-width)) 0px 0px 0px $selected-bar-color;
  }
  &.inner-bar {
    background: linear-gradient(to right, $selected-bar-color var(--portal-selected-bar-width), $selected-background-color var(--portal-selected-bar-width), $selected-background-color 100%) !important;
  }
}
