.mat-menu-item:hover,
.mat-menu-item.cdk-program-focused,
.mat-menu-item.cdk-keyboard-focused,
.mat-menu-item-highlighted {
  &:not([disabled]) {
    background: #f1f8f0;
  }
}

.mat-menu-item {
  padding: 0 20px;
}

.mat-mdc-menu-content {
  background-color: white;
}

.mat-mdc-menu-panel {
  max-width: max-content !important;
  overflow: hidden !important;
}
