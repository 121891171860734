.mat-radio-label {
  white-space: normal !important;
}

.mdc-radio__outer-circle {
  border-width: 1px !important;
}

.mat-radio-inner-circle {
  height: 24px !important;
  width: 24px !important;
  top: -2px !important;
  left: -2px !important;
}

.portal-radio-aligned-top {
  &::ng-deep .mat-internal-form-field {
    align-items: flex-start !important;
  }
}
