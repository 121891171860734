// Colors
$rich-green: #47a540;
$forest: #106b2e;
$iced-mint: #f1f8f0;
$burnt-orange: #ea7006;
$orange: #ff9600;
$pewter: #c6c6ca;
$red: #d52504;
$mat-error: #d52504;
$red-secondary: #e24028;
$blue: #2486f7;
$green-tea: #daedd9;
$slate-grey: #636365;
$elephant-grey: #7c7c7e;
$haze-dark: #959597;
$limed-spruce: #37474f;
$salmor: #ffdbd2;
$silver: #dfdfe3;
$haze: #aeaeb0;
$misty: #f8f8fc;
$disabled-grey: #c6c6ca;
$charcoal: #191919;
$border-silver: #dfdfe3;
$white: #fff;
$tangerine: rgba(255, 150, 0, 0.2);

$divider: #e7eaec;
$disabled-input-text: rgba(0, 0, 0, 0.38);
$mat-input-label-color: rgba(0, 0, 0, 0.54);
$disable-mask-color: rgba(255, 255, 255, 0.3);
$orange-warning-bg: rgba(255, 150, 0, 0.1);
$gray-disabled-bg: rgba(149, 149, 151, 0.1);

$focus-gray: #efeff1;

$selected-background-color: #DAEDD9;
$selected-bar-color: #47A540;
